import { makeStyles } from "@material-ui/core";


const useStyle = makeStyles({
    wrapper:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh"
    },
    video: {
        zIndex: -1,
        objectFit: "cover",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0
    },
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "rgba(255, 255, 255, 0.6);",
        width: "350px",
        borderRadius: 5,
        boxShadow: "1px 0px 5px 1px #FFF",
        '& button': {
            width: 250,
            margin: "25px 0"
        }
    },
    logo: {
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        width: "100%",
        height: "200px",
        background: "#eaeaea",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& img':{
            width: 128,
            height: 128
        }
    },
    input: {
        width: 250,
        marginTop: 20
    }
})
export default useStyle;
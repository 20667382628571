import { useState, useEffect, createContext, useContext } from "react";

// @api
import api from "services/axios"

// @socket io
import io from "socket.io-client";

// @fn
import { scrollBottom, sendNotify, getNumber } from "utils/fn"

const Context = createContext({});

export const socket = io('http://localhost:3333');
socket.on('connect', () => {
    console.log("» Conectado ao servidor socket io.");
})

export default function SupportContext({ children }) {
    const [supports, setSupports] = useState({ open: [], all: [] });
    const [support, setSupport] = useState({});
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        api.get('/supports').then( res => {
            if ( res.status === 200 ) {
                const all = res.data.registers.filter( item => item.status !== null );
                const open = res.data.registers.filter( item => item.status === "aberto" );
                setSupports({
                    all,
                    open
                });
            }
        });

        if ( Notification.permission === "default" ) {
            Notification.requestPermission();
        }

        // functions socket
        function onSupportMessage( data ) {
            setMessages(m => [...m, data]);
            
            sendNotify({
                title: `Nova mensagem de ${getNumber(data.author)}`,
                content: data.content,
                icon: "https://i0.wp.com/www.smartparent.in/wp-content/uploads/2018/06/Whatsapp-Icon.png"
            })
            
            api.get('/supports').then( res => {
                if ( res.status === 200 ) {
                    const all = res.data.registers.filter( item => item.status != null );
                    const open = res.data.registers.filter( item => item.status == "aberto" );
                    setSupports({
                        all,
                        open
                    });
                }
            });

            setTimeout(() => {
                scrollBottom();
            }, 200);
        }
        
        function onUpdateSupportList( data ) {
            if ( data.updated ){
                api.get('/supports').then( res => {
                    if ( res.status === 200 ) {
                        const all = res.data.registers.filter( item => item.status !== null );
                        const open = res.data.registers.filter( item => item.status == "aberto" );
                        setSupports({
                            all,
                            open
                        });
                    }
                });
            }
        }

        // event socket
        socket.on('support.message', onSupportMessage);
        socket.on('updateSupports', onUpdateSupportList);

        // unmount
        return () => {
            socket.off('support.message', onSupportMessage);
            socket.off('updateSupports', onUpdateSupportList);
        }
    }, []);

    return <Context.Provider value={{ supports, setSupports, support, setSupport, messages, setMessages }}>{children}</Context.Provider>
}

export function useSupports() {
    const { supports, setSupports } = useContext(Context);
    return { supports, setSupports };
}

export function useSupport() {
    const { support, setSupport, messages, setMessages } = useContext(Context);
    return { support, setSupport, messages, setMessages };
}
import axios from "axios";
import { getToken, removeToken } from "utils/auth";


const api = axios.create({
    baseURL: "http://localhost:3333/v1"
})

api.interceptors.request.use(function (request) {
    const token = getToken();
    if ( token ) {
        request.headers['x-access-token'] = token;
    }
    return request
}, function (error) {
    return error;
})


api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    switch(error.message){
        case 'Network Error': {
            return {
                status: 503,
                error: {
                    code: error.message,
                    message: "Servidor esta indisponível, contate o webmaster do sistema para fazer a mantuenção do servidor."
                }
            }
        }
        default: {}
    }
    
    if ( error.response.status === 401 ) {
        removeToken();
        window.location.pathname = "/";
    }
    return error;
});
export default api;



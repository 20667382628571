import { Route, Redirect } from "react-router-dom"
import { getToken } from "utils/auth";

export default function ProtectedRouter({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={ props => 
                getToken() ? (
                    <Component {...props}/>
                ) : (
                <Redirect
                    to={{
                        pathname: "/",
                        state: { from: props.location }
                    }}
                />
            )}
        />
    )
}
import { createContext, useContext, useState, useEffect } from "react"
import api from "services/axios";

const Context = createContext();

export default function UserContext({ children }) {
    const [user, setUser] = useState({});

    useEffect(() => {
        api.get('/users/me').then( res => {            
            if ( res.status === 200 ) {
                setUser(res.data);
            }
        })
    }, []);
    return <Context.Provider value={{ user }}>{children}</Context.Provider>
}

export function useUser() {
    const { user } = useContext(Context);
    return user;
}
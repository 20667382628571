import { makeStyles } from "@material-ui/core";
export default makeStyles({
    wrapper: {
        minWidth: "100%",
        minHeight: "100%",
        display: "flex",
    },
    left: {
        width: "20%",
        height: "100vh",
        background: "#2e2e2f",
        borderRight: "1px solid #696969"
    },
    content: {
        width: "80%",
        height: "100vh",
        gridArea: "content",
    }
})
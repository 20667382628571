import { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import api from "services/axios";

// @style
import useStyle from "./style";

// @midia
import logo from "storage/logo.png";

// @components
import Alert from "components/alert";

// @utils
import { setToken } from "utils/auth"


export default function Login() {
    const videoRand = Math.floor(Math.random() * 3) + 1;
    const style = useStyle();
    const [values, setValues] = useState({ username: '', password: ''});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ content: "", show: false, error: false });
    
    async function onSubmit(event) {
        event.preventDefault();
        
        setLoading(true);

        const response = await api.post("/users/login", { username: values.username, password: values.password });
        if ( response.status === 503 ) {
            setMessage({ content: response.error.message, error: true, show: true });
            return;
        }
        else if ( response.status === 200 ) {
            setMessage({ content: "Login efetuado com sucesso!", error:false, show: true });
            setToken(response.data.token);
            window.location.pathname = "/dashboard";
            return;
        }

        else if ( response.response.status === 401 ) {
            setMessage({ content: response.response.data.error, error:true, show: true });
        }
        setLoading(false);        
    }

    function onChangeValue(event){
        const { name, value } = event.target;
        setValues({...values, [name]:value });
    }

    return (
        <div className={style.wrapper}>
            <video className={style.video} autoPlay="autoplay" muted="muted" loop="loop">
                <source src={`https://infonettelecom.net.br/central_assinante_web/assets/movie/back-login-${videoRand}.mp4`} type="video/mp4" className="video_source" />
            </video>

            <form onSubmit={onSubmit} className={style.form}>
                <div className={style.logo}>
                    <img src={logo} alt="Infonet"/>
                </div>

                <TextField
                    label="Usuário"
                    name="username"
                    type="text"
                    className={style.input}
                    onChange={onChangeValue}
                    value={values.username}
                    fullWidth
                />
                <TextField
                    label="Senha"
                    name="password"
                    type="password"
                    className={style.input}
                    onChange={onChangeValue}
                    value={values.password}
                    fullWidth
                />
                <Button variant="contained" color="primary" type="submit" disabled={loading}>Entrar <ExitToApp style={{ fontSize: 18, marginLeft: 5 }}/></Button>
                <Alert show={message.show} severity={message.error ? "error" : "success"}>{message.content}</Alert>
            </form>
            
        </div>
    )
}
import {  useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// @material-ui
import { Avatar, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Select, InputLabel } from "@material-ui/core";
import { Send as SendICon, AttachFile, MoreVert } from "@material-ui/icons";

// @style
import useStyle from "./style";

// @context
import { useUser } from "context/userContext";
import { useSupport, socket } from "context/supportContext";

// @api
import api from "services/axios";


// @fn
import { scrollBottom, getNumber } from "utils/fn";

export default function Support() {
    const { id } = useParams();
    const style = useStyle();
    
    //
    const [optionsMenu, setOptionsMenu] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [attendantSelected, setAttendantSelected] = useState({});

    //
    const [message, setMessage] = useState("");
    const [attendants, setAttendants] = useState([]);

    // context
    const { support, setSupport, messages, setMessages } = useSupport();

    // informações do usuário
    const user = useUser();

    useEffect(() => {
        api.get('/users').then( res => {
            if ( res.status === 200 ) {
                setAttendants(res.data.registers);
            }
        })
    }, []);
    useEffect(() => {
        api.get(`/messages/support/${id}`).then( res => {
            if ( res.status === 200 ) {
                setMessages(res.data.registers);
                scrollBottom();
            }
        });

        api.get(`/supports/${id}`).then( res => {
            if ( res.status === 200 ) {
                setSupport(res.data);
            }
        });
        

        // function socket
        function onUpdateSupportList( data ) {
            if ( data.updated ) {
                api.get(`/supports/${id}`).then( res => {
                    if ( res.status === 200 ) {
                        setSupport(res.data);
                    }
                });
            }
        }

        // socket event
        socket.on('updateSupports', onUpdateSupportList);

        // unmount
        return () => {
            socket.off('updateSupports', onUpdateSupportList);
        }
    }, [id]);


    function onChangeMessage(event) {
        event.preventDefault();
        setMessage(event.target.value);
    }

    async function onSubmitMessage(event) {
        event.preventDefault();
        if ( !message.length )return;
        
        const data = {
            attendant_id: user.id,
            content: message,
            support_id: support.id,
            number: support.number
        }
        socket.emit('support.message', data);
        setMessages(m => [...m, data]);

        setMessage("");
        scrollBottom();
    }

    function onCloseOptionsMenu() {
        setOptionsMenu(null);
    }

    async function Assumir() {
        const res = await api.put(`/supports/${id}`, { status: "em progresso", attendant: user.id });
        if ( res.status === 200 ) {
            if ( res.data.updated ) {
                const data = {
                    attendant_id: user.id,
                    content: `${user.username} iniciou seu atendimento!`,
                    support_id: support.id,
                    number: support.number
                }
                socket.emit("support.message", data);
                setMessages(m => [...m, data]);
                scrollBottom();
            }
        }
        else if ( res.status === 202 ) {
            alert(res.data.message);
        }
        onCloseOptionsMenu(null);
    }

    async function Finalizar() {
        const res = await api.put(`/supports/${id}`, { status: "fechado", number: "" });
        if ( res.status === 200 ) {
            if ( res.data.updated ) {
                const data = {
                    attendant_id: user.id,
                    content: `${user.username} finalizou seu chamado!`,
                    support_id: support.id,
                    number: support.number
                }
                socket.emit("support.message", data);
                setMessages(m => [...m, data]);
                scrollBottom();
            }
        }
        else if ( res.status === 202 ) {
            alert(res.data.message);
        }
        onCloseOptionsMenu(null);
    }

    async function openDialog() {
        setDialog(true);
    }

    async function Encaminhar() {
        const res = await api.put(`/supports/${id}`, { attendant: attendantSelected });
        if ( res.status === 200 ) {
            if ( res.data.updated ) {
                const data = {
                    attendant_id: user.id,
                    content: `${user.username} transferiu você, aguarde um momento por favor`,
                    support_id: support.id,
                    number: support.number
                }
                socket.emit("support.message", data);
                setMessages(m => [...m, data]);
                scrollBottom();
            }
        }
        else if ( res.status === 202 ) {
            alert(res.data.message);
        }
    }
    
    return (
        <div className={style.content}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", color: "#FFF", background: "#2E2E2F", height: "10vh" }}>
                <div style={{ display: "flex", alignItems: "center", marginLeft: 15 }}>
                    <Avatar src={support.avatar_url} />
                    <div style={{ marginLeft: 10, display: "flex", flexDirection: "column", fontSize: 14 }}>
                        <span>{support.name}</span>
                        <span>{getNumber(support.number)}</span>
                    </div>
                </div>

                <MoreVert
                    onClick={(event) => setOptionsMenu(event.currentTarget)}
                    aria-controls="options-menu"
                    aria-haspopup="true"
                    style={{ color: "#FFF", marginRight: 15, cursor: "pointer" }}
                />
                <Menu
                    id="options-menu"
                    anchorEl={optionsMenu}
                    open={Boolean(optionsMenu)}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 50, left: 2000 }}
                    onClose={onCloseOptionsMenu}
                >
                    <MenuItem style={{ fontSize: 12 }} onClick={Assumir}    disabled={support.status === "fechado" || support.attendant !== user.id && support.attendant}>Assumir</MenuItem>
                    <MenuItem style={{ fontSize: 12 }} onClick={Finalizar}  disabled={support.status === "fechado" || support.attendant !== user.id}>Finalizar</MenuItem>
                    <MenuItem aria-controls="secondary-menu" aria-haspopup="true" style={{ fontSize: 12 }} onClick={openDialog} disabled={support.status === "fechado" || support.attendant !== user.id}>
                        Encaminhar
                    </MenuItem>
                </Menu>


            </div>
            <div style={{ overflow: 'auto', height: "80vh", }} id="messages">
            {messages.map( (message, key) => (
                <div id={`message-${message.id}`} key={key} className={!message.attendant_id ? style.messageLeft : style.messageRight}>
                    <div className={style.messageContent}>
                        {message.image ? 
                            <div>
                                <img src={`http://localhost:3333${message.image}`} alt={message.content} width={300} height={300}/>
                                <p>{message.content}</p>
                            </div>
                            :
                            <p>{message.content}</p>
                        }
                        <span style={{ display: "flex", justifyContent: "flex-end", fontSize: 12, fontWeight: 600 }}>{message.created_at}</span>
                    </div>
                </div>
            ))}
            </div>

            <div className={style.areaMessage}>
                <div className={style.attachFile}>
                    <AttachFile style={{ cursor: "pointer" }}/>
                </div>

                <div className={style.textarea}>
                    <form onSubmit={onSubmitMessage} style={{ width: "90%" }}>
                        <input
                            disabled={support.status === "fechado" || support.attendant !== user.id }
                            onChange={onChangeMessage}
                            value={message}
                            placeholder="digite uma mensagem."
                        />
                    </form>
                </div>

                <div className={style.sendButton}>
                    <SendICon onClick={onSubmitMessage} style={{ cursor: "pointer" }}/>
                </div>
            </div>

            <Dialog open={dialog} onClose={() => setDialog(false) } fullWidth>
                <DialogTitle>Encaminhar para...</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Select fullWidth onChange={(event) => setAttendantSelected(event.target.value) } value={attendantSelected}>
                            <MenuItem value="0" disabled>Selecione o atendente</MenuItem>
                            {attendants.map( item => (
                                <MenuItem key={item.id} value={item.id} disabled={user.id === item.id}>{item.username}</MenuItem>
                            ))}
                        </Select>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="default" variant="text" onClick={() => setDialog(false) }>Fechar</Button>
                    <Button color="primary" variant="contained" size="small" onClick={Encaminhar}>Encaminhar</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
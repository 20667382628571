import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// @utils
import ProtectedRouter from "utils/privateRouter"

// @pages
import Login from "pages/login"
import Dashboard from "pages/dashboard"

function Routes() {
  document.title = "Infonet";
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login}/>
        <ProtectedRouter path="/dashboard" component={Dashboard}/>
      </Switch>
    </Router>
  );
}

export default Routes;

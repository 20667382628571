import { BrowserRouter as Router, Route } from "react-router-dom";

// @compnents
import Supports from "components/leftComponent.js";

// @style
import useStyle from "pages/dashboard/style"

// @pages
import Support from "pages/dashboard/support/index.js";

// @context
import UserProvider from "context/userContext";
import SupportContext from "context/supportContext";


export default function Dashboard() {
    const style = useStyle();

    return (
        <UserProvider>
            <Router basename="dashboard">
                <SupportContext>
                    <div className={style.wrapper}>
                        <div className={style.left}>
                            <Supports />
                        </div>
                        <div className={style.content}>
                            <Route exact path="/support/:id" component={Support}/>
                        </div>
                    </div>
                    </SupportContext>
            </Router>
        </UserProvider>
    )
}
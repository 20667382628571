export function scrollBottom() {
    const objDiv = document.getElementById("messages");
    objDiv.scrollTop = objDiv.scrollHeight;
}

export function sendNotify({ title, content, icon }) {
    if ( Notification.permission !== "granted" )return
    if ( !document.hasFocus() ) {

        // tocar som
        const audio = new Audio("http://localhost:3000/notify.mp3");
        audio.play();

        new Notification(title, {
            body: content,
            icon
        })
    }
}

export function getNumber(number) {
    if ( number ) {
        return `+${number?.match(/\d+/)}`
    }
}
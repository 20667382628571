import { Alert } from "@material-ui/lab";

export default function AlertComponent({ children, show, ...rest }) {
    return (
        show ?
            <Alert {...rest}>
                {children}
            </Alert>
        : ""
    )
}
import { makeStyles, Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// @icons
import { ExitToApp } from "@material-ui/icons";

// @useUser
import { useUser } from "context/userContext";
import { useSupports } from "context/supportContext";

// @utils
import { removeToken } from "utils/auth";


export default function MenuLeft() {
    const style = useStyle();
    const history = useHistory();
    const user = useUser();
    const { supports } = useSupports();
    
    console.log(supports);

    function onPushToSupportMessages(id) {
        history.push(`/support/${id}`);
    }

    function statusFormat(status) {
        if ( status ) {
            switch(status){
                case 'aberto': {
                    return <span style={{ background: "#074207", borderRadius: 5, padding: 5, fontSize: 10 }}>ABERTO</span>
                }
                case 'fechado':{
                    return <span style={{ background: "#420707", borderRadius: 5, padding: 5, fontSize: 10 }}>FECHADO</span>
                }
                case 'em progresso':{
                    return <span style={{ background: "#072642", borderRadius: 5, padding: 5, fontSize: 7 }}>EM PROGRESSO</span>
                }
                default: return;
            }
        }
    }
    
    function LogOut() {
        removeToken();
        history.go("/");
    }

    return (
        <div>
            <div className={style.userInfo}>
                <div style={{ marginRight: 20 }}>
                    <Avatar src={user.avatar_url} style={{ width: 64, height: 64 }}/>
                </div>
                <div>
                    <div style={{ fontSize: 14, fontFamily: "'Roboto slab', sans-serif" }}>{user.username}</div>
                    <div><ExitToApp onClick={LogOut} style={{ cursor: "pointer", fontSize: 16, marginTop: 5 }}/></div>
                </div>
            </div>
            
            <div className={style.supportsItems}>
            {supports.all.length > 0 ? supports.all.map( (item, index) => (
                <div key={index} className={style.itemSupport} onClick={() => onPushToSupportMessages(item.id)}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 5 }}>
                        <div>
                            {item.avatar_url ? 
                                <img src={item.avatar_url} alt="pic-profiler" width="24" height="24"/> :
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212" width="32" height="32"><path fill="#DFE5E7" class="background" d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"></path><path fill="#FFF" class="primary" d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z"></path></svg>
                            }                            
                        </div>
                        <div style={{ marginLeft: 10, display: "flex", flexDirection: "column" }}>
                            <span style={{ fontSize: 16 }}>{item.subject}</span>
                            <span style={{ fontSize: 12 }}>{item.name}</span>
                        </div>
                        <div style={{ margin: "0 20px" }}>
                            {statusFormat(item.status)}
                        </div>
                    </div>
                </div>
            )) : <div style={{ color: "#FFF", fontSize: 12, textAlign: "center", marginTop: 10 }}>Não tem nenhum suporte aberto pelo whatsapp</div>}
            </div>
        </div>
    )
}

const useStyle = makeStyles({
    userInfo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 100,
        color: "#FFF",
    },
    supportsItems: {
        height: "75vh",
        overflowY: "auto",
        background: "#666"
    },
    itemSupport: {
        cursor: "pointer",
        width: "100%",
        background: "#333",
        color: "#FFF",
        padding: "10px 0",
        transition: "background 250ms",
        '&:hover':{
            background: "#444"
        }
    },
    itemStatus: {
        fontSize: "0.8rem",
        borderRadius: 5,
        padding: 2,
        background: "#00BBFF"
    }
})
import { makeStyles } from "@material-ui/core"
export default makeStyles({
    content: {
        minHeight: "100vh",
        overflowY: "auto",
        background: "url('https://wallpaperaccess.com/full/1288171.jpg');",
        backgroundColor: "#000",
        backgroundSize: "cover"
    },

    messageLeft: {
        display: "flex",
        justifyContent: "start",
        marginTop: 10,
        marginLeft: 30
    },
    messageRight: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 10,
        marginRight: 30
    },

    messageContent: {
        width: "fit-content",
        minWidth: 100,
        padding: 10,
        marginBottom: 5,
        background: "#FFF",
        borderRadius: 5,
        fontSize: 14
    },

    areaMessage: {
        background: "#2E2E2F",
        height: "10vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly"
    },

    attachFile: {
        display: "flex",
        justifyContent: "center",
        width: "5%",
        color: "#FFF"
    },

    textarea: {
        width: "100%",
        height: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& input': {
            width: "100%",
            borderRadius: 20,
            padding: 10,
            border: "none",
            outline: "none",
            resize: "none"
        }
    },
    sendButton: {
        width: "5%",
        color: "#FFF",
        display: "flex",
        justifyContent: "center",
    },
    
})
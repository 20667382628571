import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import "global.css";

// @theme
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0097d2",
      light: "#0097d2"
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
